.card-active {
    height: auto !important;
    overflow: auto !important; }

.event-card {
    margin: 22px 0 22px 0;
    width: auto;
    height: auto;
    display: grid;
    grid-template-columns: 20% 50% 30%;
    //cursor: pointer
    overflow: hidden;
    @include md {
      grid-template-columns: 20% 80%; }
    @include sm {
      grid-template-columns: 100%; }
    border: 6px solid $primary-bg-color;
    background: #fff;
    -webkit-box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.3);
    box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.3);
    transition: $transition;
    @include md {
      height: auto; }
    &:hover {
      box-shadow: 0px 6px 10px 0px rgba(0,0,0,0.4);
      perspective: 1000px; }
    .date {
      margin: 22px 0 22px 0;
      padding: 0 12px 0 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      @include md {
        grid-row-start: 2; }
      @include sm {
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 9px;
        padding-bottom: 9px;
        max-width: none;
        background: none;
        margin: 22px 12px 22px 22px; }
      max-width: 150px;
      text-align: center;
      background-image: url('../images/sections/event-card/line.svg');
      background-position: right;
      background-repeat: no-repeat;
      background-size: 10px 220px;
      div {
        @include sm {
          display: flex;
          align-items: center;
          .day {
            padding: 0; }
          .month {
            padding-left: 22px;
            margin: 0;
            @include font-size(3);
            @include sm {
              letter-spacing: 7px; } } } }
      .day {
        font-family: $font-headline;
        display: block;
        line-height: 1.1;
        @include font-size(5);
        color: red;
        background: -webkit-linear-gradient(top, $gradient-primary 0%, $gradient-secondary 100%);
        //text-shadow: 1px 4px 6px #fff, 0 0 0 #000, 1px 4px 6px #fff
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        @include md {
          @include font-size(3); } }

      .month {
        display: block;
        font-family: $font-headline;
        @include font-size(1.5);
        color: $gradient-secondary;
        margin: 0 auto;
        text-transform: uppercase;
        letter-spacing: .5em; } }
    .info {
      &.wide {
        width: 140%; }
      width: 90%;
      @include md {
        width: 100%; }
      padding-bottom: 22px;
      @include md {
        grid-row-start: 2;
        padding-bottom: 22px;
        padding-right: 22px;
        max-width: 93%; }
      @include sm {
        grid-row-start: 3;
        padding-top: 0;
        max-width: 90%;
        margin: 0 auto; }
      padding-top: 22px;
      padding-left: 22px;
      color: $color-shadow;
      .next {
        display: block;
        font-family: $font-headline-sm;
        line-height: 2em;
        @include font-size(1.3);
        @include sm {
          display: none; } }
      .event-name {
        font-family: $font-headline;
        text-transform: uppercase;
        line-height: 1.1em;
        @include font-size(3.3);
        @include sm {
          @include font-size(3);
          word-wrap: break-word; } }
      .more {
        display: flex;
        margin-top: 7px;
        .time {
          span {
            padding-left: 54px; } }
        .venue {
          span {
            padding-left: 40px; } }
        @media (max-width: 480px) {
          display: block;
          div:first-child {
            margin-bottom: 12px; } }
        span {
          font-family: $font-headline-sm;
          @include font-size(1.3);
          padding-top: 9px; }
        div {
          span:first-child {
            color: $primary-bg-color;
            display: block;
            margin-right: 22px; } }
        .time {
          width: 66%;
          @include md {
            width: 100%; } }
        .time::before {
          content: url('../images/sections/event-card/calendar.svg');
          position: absolute;
          transform: scale(.6);
          display: inline-block; }
        .venue::before {
          content: url('../images/sections/event-card/pin.svg');
          position: absolute;
          transform: scale(.6);
          display: inline-block; } } }
    .image {
      .event-image {
        background-position: center center;
        border-radius: 8px;
        @include lg {
          width: 100%; }
        @include md {
          width: 500% !important;
          background-size: cover !important;
          background-repeat: no-repeat !important;
          grid-row-start: 2;
          height: 220px; }
        @include sm {
          grid-row-start: 1;
          width: 100% !important;
          height: 220px; }
        height: 100%;
        transform: scale(.95);
        background-size: cover !important;
        &::after {
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(237,237,237,1) 100%);
          background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,0)), color-stop(100%, rgba(237,237,237,1)));
          background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(237,237,237,1) 100%);
          background: -o-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(237,237,237,1) 100%);
          background: -ms-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(237,237,237,1) 100%);
          background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(237,237,237,1) 100%);
          opacity: .3; } } }
    .details {
      padding: 0px 22px 0px 22px;
      grid-row: 2 / span 1;
      grid-column: 1 / span 3;
      display: flex;
      flex-wrap: wrap;
      p {
        padding-top: 5px;
        @include font-size(1.5);
        line-height: 1.88em; }
      @include sm {
        display: block; }
      div {
        width: 50%;
        @include sm {
          margin-bottom: 22px; }
        h1 {
          @include sm {
            margin-bottom: 0; } }
        @include sm {
          width: 100%; } }
      .controls {
        text-align: right;
        @include sm {
          text-align: left; } }
      p {
        margin-top: -8px; }
      h1.close {
        font-family: $font-headline;
        font-weight: 900;
        display: inline-block;
        text-transform: uppercase;
        background: $gradient-primary;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        @include font-size(4);
        @include sm {
          margin-top: 0;
          margin-bottom: 24px; } }
      @include md {
        grid-row: 3 / span 1;
        grid-column: 1 / span 3; }
      @include sm {
        grid-row: 4 / span 1; } } }
