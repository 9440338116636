$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1200px;

@mixin sm {
    @media (max-width: $screen-md-min) {
        @content; } }

// Medium devices
@mixin md {
   @media (max-width: $screen-lg-min) {
       @content; } }

// Large devices
@mixin lg {
   @media (max-width: $screen-xl-min) {
       @content; } }

// Extra large devices
@mixin xl {
   @media (max-width: $screen-xl-min) {
       @content; } }

@mixin xxl {
  @media (min-width: $screen-xl-min) {
      @content; } }



// Define vertical, horizontal, or both position
@mixin center($position) {
  position: absolute;
  @if $position == 'vertical' {
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%); }

  @else if $position == 'horizontal' {
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translate(-50%); }

  @else if $position == 'both' {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); } }


// Using the mixin
.foo {
  @include center(both); }

.foo-parent {
  position: relative; }


@mixin fontFace($family, $src, $style: normal, $weight: normal) {
	@font-face {
		font-family: $family;
		src:url('#{$src}.eot') {}
		src:url('#{$src}.eot?#iefix') format('embedded-opentype'),url('#{$src}.woff') format('woff'),url('#{$src}.ttf') format('truetype'),url('#{$src}.svg##{$family}') format('svg') {}
		font-style: $style;
		font-weight: $weight; } }

@mixin font-size($sizeValue: 1.6) {
  font-size: ($sizeValue * 10) + px;
  font-size: $sizeValue + rem; }
