.archive-thumb {
    margin: 22px 0 22px 0;
    padding: 18px 18px 18px 18px;
    border: 6px solid $primary-bg-color;
    img {
        width: 100%; } }

.gallery {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 24px;
    padding: 0;
    li > div {
        text-align: center; }
    @include md {
        grid-template-columns: 1fr 1fr; }
    @include sm {
        grid-template-columns: 1fr; } }

