@import '_includes/_vars';
@import '_includes/_mixins';
@import '_includes/zigzag';
@import '_includes/_fonts';


/* Component import */
@import '_includes/components/navigation';
@import '_includes/components/event-card';
@import '_includes/components/archive';
@import '_includes/components/team';

/* Development Classes */
.to-be-announced:hover {
    &:after,
    &:focus,
    &:active {
        content: ' Nächste Woche';
        display: inline-block;
        position: relative;
        margin-left: 22px; } }

html,
body {
    padding: 0;
    margin: 0;
    font-size: 62.5%;
    font-family: $font-paragraph;
    @include md {
      overflow-x: hidden; } }

.inline {
    display: inline-block; }

.invisible {
    display: none; }

.invisible-doens {
    visibility: hidden; }

.hidden {
    visibility: hidden; }

.first {
    margin-top: 120px; }

button {
    background: transparent;
    border: 0;
    padding: 7px 0 7px 0;
    margin: 12px 5px 12px 5px;
    border-top: 3px solid #fff;
    border-bottom: 3px solid #fff;
    text-transform: uppercase;
    color: #ffffff;
    @include font-size(2);
    font-family: $font-headline-sm;
    transition: $transition;
    &:hover {
      color: $primary-bg-color;
      transform: scale(1.02);
      border-top: 3px solid $primary-bg-color;
      border-bottom: 3px solid $primary-bg-color; } }

@keyframes gradient {
  0% {
    background-position: 0% 0%; }
  100% {
    background-position: 100% 100%; } }

.container {
    $container: 900px;
    max-width: $container;
    margin: 0 auto;
    @media (max-width: $container) {
      margin-left: 22px;
      margin-right: 22px; }
    &.next-events {
      position: relative;
      top: -72px; }
    &.events {
      text-align: center; }
    @include sm {
      margin-left: 12px;
      margin-right: 12px; } }

.content {
  margin-top: 72px;
  margin-bottom: 72px; }

.hero-wrapper {
    display: grid;
    grid-template-columns: 2fr auto 2fr;
    min-height: 70vh;
    & > :first-child {
        text-align: right; }
    & > :last-child {
        text-align: left; }
    .letters {
        padding-top: 32vh;
        @include sm {
          padding-top: 32vh; }
        img {
            height: 88px;
            @include sm {
              height: 35px; }
            /* TODO Für Buchstaben Breakpoints angeben */ } }
    .metronomy-wrapper {
        display: grid; }
    .metronomy {
        align-self: center;
        overflow: hidden;
        @include sm {
            transform: scale(1); }
        @include xl {
            transform: scale(1); }
        @include xxl {
            transform: scale(.8); }
        img {
            width: 100%; }
        .metronomy-background {
 }            //border: 5px solid blue
        .metronomy-ticker {
            position: relative;
            //border: 5px solid green
            filter: blur(.7px);
            margin-top: -113%;
            transform-origin: 48% 70%;
            animation: rotate 1.5s ease-in-out infinite; } } }

.interaction {
  position: absolute;
  max-width: 900px;
  margin: 0 auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 10;
  display: grid;
  grid-template-columns: 2fr auto 2fr;
  grid-template-rows: 75vh;
  @include md {
    display: none; } }

#kommen {
  left: 0; }

#gehen {
  right: 0; }

.team-playful {
  margin-bottom: 8px;
  display: block; }

.gehen-playful {
  margin-bottom: 8px;
  display: block;
  a {
    color: #fff;
    &:before {
      background: #fff; }
    &:after {
      background: #fff; } } }

.playful-navigation {
  position: relative;
  margin: 0 auto;
  z-index: 100;
  @include xl {
    top: 50%; }
  @include xxl {
    top: 65%; }
  div > a {
    animation: shake 4s cubic-bezier(.36, .07, .19, .97) infinite;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px; } }

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0); }
  20%, 80% {
    transform: translate3d(2px, 0, 0); }
  40%, 60% {
    transform: translate3d(4px, 0, 0); } }


@keyframes wiggle {
  0% {
    tranform: translateX(0);
    tranform: translateY(0); }
  10% {
    tranform: translateX(3px);
    tranform: translateY(4px); }
  20% {
    tranform: translateX(-2px);
    tranform: translateY(-1px); }
  20% {
    tranform: translateX(3px);
    tranform: translateY(-1px); }
  30% {
    tranform: translateX(1px);
    tranform: translateY(0px); }
  30% {
    tranform: translateX(1px);
    tranform: translateY(0px); } }

.grid.flexy {
  display: grid;
  grid-column-gap: 44px;
  grid-template-columns: 30% 60%;
  @include sm {
    grid-template-columns: auto;
    text-align: center; }
  .bubble {
    display: inline-table;
    background: $color-shadow;
    border-radius: 13px;
    padding: 12px 22px 0px 22px;
    &::after {
      content: '';
      display: block;
      position: relative;
      left: calc(100% + 7px);
      bottom: 22px;
      width: 25px;
      height: 25px;
      transform: rotate(45deg);
      background: $color-shadow; }
    h1 {
      margin: 0; } } }

@keyframes rotate {
    0% {
        transform: rotate(0deg); }
    50% {
        transform: rotate(-81.75deg); }
    100% {
        transform: rotate(0deg); } }

.image-split {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  grid-column-gap: 50px;
  @include md {
     grid-template-columns: auto;
     justify-content: center;
     text-align: center; }
  img {
    margin: 0 auto;
    max-width: 300px; } }

.sponsor-grid {
  display: grid;
  grid-column-gap: 34px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  @include md {
    grid-template-columns: 1fr 1fr 1fr; }
  .grid-element {
    text-align: center;
    div {
      min-height: 200px;
      display: flex;
      align-items: center;
      justify-content: center; }
    img {
      max-width: 100%;
      max-height: 175px;
      height: auto; } }

  @include sm {
    grid-template-columns: 1fr; } }


.footer-grid {
  display: flex;
  div {
    padding: 0 12px 0 12px;
    width: 50%;
    margin: 0;
    transform: scale(.7);
    h1 {
      margin-top: 0;
      margin-bottom: 0;
      @include font-size(5); }
    a {
        font-family: $font-headline;
        font-weight: 900;
        display: block;
        text-transform: uppercase;
        cursor: pointer;
        background: #fff;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        @include font-size(3); } } }

.impressum-wrapper {
  h2 {
    color: #fff;
    text-align: left;
    margin-bottom: 0 !important;
    margin-left: 0; }
  p {
    @include font-size(1.5);
    line-height: 2em; } }

.logo-min {
  position: relative;
  top: 30px;
  height: 100px;
  @include sm {
    top: 15px;
    height: 50px; } }


.copyright {
  padding-top: 44px;
  text-align: center;
  span {
    font-family: $font-headline;
    font-weight: 900;
    display: block;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    background: #fff;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @include font-size(2); } }


#continuous-slider {
  display: block;
  margin-left: auto;
  margin-right: auto;

  .continuous-slider--wrap {
    display: block;
    width: 95%;
    margin: 0 auto;
    overflow: hidden;

    ul {
      display: block;
      list-style: none;
      position: relative;
      margin-left: auto;
      margin-right: auto;

      li {
        display: block;
        float: left;
        position: relative;
        width: 200px;
        height: 200px;
        padding-left: 44px;
        padding-right: 44px;
        line-height: 200px;
        text-align: center;

        img {
          vertical-align: middle;
          max-width: 100%;
          max-height: 150px;
          filter: grayscale(100%);
          -webkit-transition: 0 linear left;
          -moz-transition: 0 linear left;
          transition: 0 linear left;
          opacity: 0.65;

          &:hover {
            opacity: 1.0;
            filter: grayscale(0); } } } } } }
