.nav-element {
    text-align: center;
    margin: 0 24px;
    margin-bottom: -5px;
    align-self: center;
    display: inline-block;
    a {
        font-family: $font-headline;
        @include font-size(2.3);
        font-weight: 600;
        padding: 2px 5px 2px 5px;
        text-transform: uppercase;
        text-decoration: none;
        color: $primary-bg-color;
        &:before {
            content: '';
            display: block;
            height: 3px;
            width: 100%;
            background: $primary-bg-color;
            opacity: 0;
            border-radius: 2px;
            transform: translateY(-10px);
            transition: 250ms ease-in-out; }
        &:after {
            content: '';
            display: block;
            width: 100%;
            height: 3px;
            border-radius: 2px;
            background: $primary-bg-color;
            opacity: 0;
            transform: translateY(10px);
            transition: 250ms ease-in-out; }
        &:hover {
            &:after {
                transform: translateY(0);
                opacity: 1; }
            &:before {
                transform: translateY(0);
                opacity: 1; } } } }

.playful {
    opacity: 0; }

.nav-hide {
    transform: translateY(-100%); }

.nav-element-wrapper {
    display: flex; }


.navigation {
    position: fixed;
    top: 0;
    z-index: 10;
    width: 100%;
    background: #fff;
    display: flex;
    justify-content: space-between;
    transition: $transition;
    @include sm {
      grid-gap: 0; }
    @media screen and (max-width: 990px) {
      grid-template-columns: 1fr 1fr; }
    grid-gap: 20px;
    padding: 8px 0 8px 0;
    margin: 0 auto;
    height: 100px;
    .logo-wrapper {
        padding-left: 44px;
        text-align: left;
        @include sm {
          padding-left: 0; }
        @media screen and (max-width: 990px) {
            text-align: left !important;
            margin-left: 24px; } }
    .logo {
        margin: 0 auto;
        height: 112px;
        &:not(:first-child) {
            @media screen and (max-width: 990px) {
                display: none; } }
        &:last-child {
            @media screen and (max-width: 990px) {
                position: relative;
                right: 0;
                display: block !important; } } } }

.icon {
    display: none; }

.nav-element-wrapper {
    @include md {
        & > .nav-element {
            display: none; }
        & > .nav-element:last-child {
            display: block;
            & .icon {
                display: block; } } } }

.responsive {
    @include md {
        display: block;
        position: absolute;
        top: 110px;
        width: 100vw;
        background: #fff;
        padding-bottom: 44px;
        & > .nav-element {
            margin: 0 auto;
            margin-top: 22px;
            margin-bottom: 22px;
            max-width: 200px;
            display: block; } } }

