//@import url('https://fonts.googleapis.com/css?family=Raleway:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i')
//@import url('https://fonts.googleapis.com/css?family=PT+Sans:400,700')

@include fontFace('GT Pressura Bold', 'fonts/gt-pressura-bold');
@include fontFace('GT Cinetype Mono', 'fonts/gt-cinetype-mono');

//$font-primary: 'Raleway'
$font-headline: 'GT Pressura Bold';
$font-headline-sm: 'GT Cinetype Mono';
$font-paragraph: 'PT Sans', sans-serif;
$font-fallback: 'Helvetica';

.white {
  color: #fff; }

a {
  font-family: $font-headline;
  text-decoration: none;
  display: block;
  color: $primary-bg-color;
  cursor: pointer;
  transition: 70ms ease-in;
  @include font-size(2);
  &:hover {
    color: $dark; } }

.fa-bars {
  font-family: $font-headline;
  &:before {
    content:  ''; } }

p {
  font-family: $font-paragraph;
  line-height: 32px;
  @include font-size(2);
  color: $gradient-secondary; }

blockquote {
  font-style: italic;
  p {
    @include font-size(3); } }

span {
  @include font-size(1.5);
  color: #000000; }


h1 {
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin: 0.3em 0;
  text-align: left !important;
  -webkit-font-smoothing: antialiased;
  font-family: $font-headline;
  font-weight: 900;
  text-transform: uppercase;
  text-align: left;
  background: $gradient-secondary;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @include font-size(4);
  &.inline {
    @include sm {
      font-size: 22px !important; } } }

h1.default {
  font-family: $font-headline;
  font-weight: 900;
  text-transform: uppercase;
  text-align: center;
  background: $gradient-secondary;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @include font-size(4);
  &.inline {
    @include sm {
      font-size: 22px !important; } } }

h1.details {
  font-family: $font-headline;
  font-weight: 900;
  text-transform: uppercase;
  text-align: left;
  margin-top: 0;
  padding: 0 !important;
  color: $color-shadow;
  @include font-size(3.3);
  &.inline {
    @include sm {
      font-size: 22px !important; } } }

h1.white {
  font-family: $font-headline;
  font-weight: 900;
  text-transform: uppercase;
  background: #fff;
  -webkit-background-clip: text;
  //-webkit-text-fill-color: transparent
  @include font-size(4); }

h1.super {
  font-family: $font-headline;
  text-transform: uppercase;
  text-align: center !important;
  margin: 0 auto;
  margin-top: 72px;
  margin-bottom: 72px !important;
  margin-bottom: 0;
  line-height: 1.4em;
  width: 80%;
  color: $primary-bg-color;
  @include font-size(7);
  @include sm {
    @include font-size(3); }
  &.white {
      color: #fff !important;
      z-index: 10; }
  &::before {
      content: '';
      display: block;
      position: relative;
      height: 5px;
      width: 100%;
      background: white;
      border-radius: 1.5px;
      @include sm {
        height: 3px; } }
  &::after {
      content: '';
      display: block;
      position: relative;
      text-align: center;
      height: 5px;
      width: 100%;
      background: white;
      border-radius: 1.5px;
      @include sm {
        height: 3px; } } }


h2 {
  font-family: $font-headline-sm;
  text-align: center;
  margin: 0 auto;
  margin-top: 0px;
  margin-bottom: 48px !important;
  margin-bottom: 0;
  line-height: 1.4em;
  width: 80%;
  color: $primary-bg-color;
  @include font-size(3);
  &.white {
      color: #fff; } }

h4 {
  font-family: $font-headline;
  text-align: center;
  margin: 0 auto;
  margin-top: 0px;
  margin-bottom: 48px !important;
  margin-bottom: 0;
  line-height: 1.4em;
  text-transform: uppercase;
  width: 80%;
  color: $primary-bg-color;
  @include font-size(2);
  &.white {
      color: #fff; }
  &.dark {
      color: $dark; } }


.nav-element span {
  @include font-size(2); }
