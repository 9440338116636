.team {
    margin-top: 24px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 24px;
    grid-row-gap: 28px;
    @include md {
        grid-template-columns: 1fr; } }

.member-wrapper {
    border-radius: 5px;
    padding: 24px;
    border: 6px solid $primary-bg-color;
    box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.3);
    h1 {
        margin-top: 22px;
        margin-bottom: 9px; }
    .avatar {
        width: 100px;
        height: 100px;
        background-size: cover;
        background-position: center center;
        border-radius: 50%;
        border: 4px solid white;
        &::after {
            content: '';
            display: block;
            position: relative;
            width: 100px;
            height: 100px;
            top: -8px;
            left: -8px;
            border-radius: 50%;
            border: 8px solid $primary-bg-color;
            z-index: -1; } }
    .title {
        font-family: $font-headline-sm; } }

.anim-cursor {
    position: absolute;
    top: -10px;
    left: 0;
    transform: rotate(3deg);
    transition: 400ms cubic-bezier(0.68, -0.15, 0.265, 1.15);
    img {
        height: 100px;
        fill: red; } }
