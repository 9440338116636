// TODO: Fix Drop Shaddow Problem
// TODO: find calculation for every width to fit the middle


$zigzag-size: 30px;

.background-image {
    background-size: cover; }

.zigzag-bottom-passive {
    position: relative;
    padding: $zigzag-size 8px $zigzag-size 8px;
    background: #fff;
    &:after {
        background: linear-gradient(-45deg, #ffffff $zigzag-size/2, transparent 0), linear-gradient(45deg, #ffffff $zigzag-size/2, transparent 0);
        background-position: left-bottom;
        background-repeat: repeat-x;
        background-size: $zigzag-size $zigzag-size;
        content: " ";
        display: block;
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        height: $zigzag-size;
        -webkit-filter: drop-shadow(rgba(0, 0, 0, .09) 0px -6px 4px);
        z-index: 100;
        @include md {
          bottom: -2px; } } }

.zigzag-bottom {
    position: relative;
    padding: $zigzag-size 8px $zigzag-size 8px;
    background: $primary-bg-color;
    &:after {
        background: linear-gradient(-45deg, #ffffff $zigzag-size/2, transparent 0), linear-gradient(45deg, #ffffff $zigzag-size/2, transparent 0);
        background-position: left-bottom;
        background-repeat: repeat-x;
        background-size: $zigzag-size $zigzag-size;
        content: " ";
        display: block;
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        height: $zigzag-size;
        -webkit-filter: drop-shadow(rgba(0, 0, 0, .09) 0px -6px 4px);
        z-index: 100;
        @include md {
          bottom: -2px; } } }

.zigzag-top {
    position: relative;
    padding: $zigzag-size 8px $zigzag-size 8px;
    background: $primary-bg-color;
    &.light {
        background: #fff; }
    &.dark {
        background: $dark; }
    &:after {
        background: linear-gradient(-45deg, #ffffff $zigzag-size/2, transparent 0), linear-gradient(45deg, #ffffff $zigzag-size/2, transparent 0);
        background-position: left-bottom;
        background-repeat: repeat-x;
        background-size: $zigzag-size $zigzag-size;
        content: " ";
        display: block;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        -webkit-filter: drop-shadow(rgba(0, 0, 0, .09) 0px -6px 4px);
        transform: rotate(180deg);
        height: $zigzag-size;
        @include md {
          bottom: -2px; } } }

.zigzag-both {
    position: relative;
    padding: $zigzag-size 8px $zigzag-size 8px;
    background: $primary-bg-color;
    &.image {
        background-size: cover; }
    &.dark {
        background: $dark; }
    &:before {
        background: linear-gradient(-45deg, #ffffff $zigzag-size/2, transparent 0), linear-gradient(45deg, #ffffff $zigzag-size/2, transparent 0);
        background-position: left-bottom;
        background-repeat: repeat-x;
        background-size: $zigzag-size $zigzag-size;
        content: " ";
        display: block;
        position: absolute;
        top: 0px;
        left: 0px;
        -webkit-filter: drop-shadow(rgba(0, 0, 0, .09) 0px -6px 4px);
        width: 100%;
        transform: rotate(180deg);
        height: $zigzag-size;
        @include md {
          top: -2px; } }
    &:after {
        background: linear-gradient(-45deg, #ffffff $zigzag-size/2, transparent 0), linear-gradient(45deg, #ffffff $zigzag-size/2, transparent 0);
        background-position: left-bottom;
        background-repeat: repeat-x;
        background-size: $zigzag-size $zigzag-size;
        content: " ";
        display: block;
        position: absolute;
        bottom: 0px;
        left: 0px;
        -webkit-filter: drop-shadow(rgba(0, 0, 0, .09) 0px -6px 4px);
        width: 100%;
        height: $zigzag-size;
        @include md {
          bottom: -2px; } } }


.zigzag-both-half {
    position: relative;
    padding: $zigzag-size 8px $zigzag-size 8px;
    background: linear-gradient(to right, #ffffff 50%, $primary-bg-color 50%);
    &.first {
      margin-top: 120px;
      padding: $zigzag-size*1.8 8px $zigzag-size*1.8 8px; }
    &:before {
        background: linear-gradient(-45deg, #ffffff $zigzag-size/2, transparent 0), linear-gradient(45deg, #ffffff $zigzag-size/2, transparent 0);
        background-position: left-bottom;
        background-repeat: repeat-x;
        background-size: $zigzag-size $zigzag-size;
        content: " ";
        display: block;
        position: absolute;
        top: 0px;
        left: 0px;
        -webkit-filter: drop-shadow(rgba(0, 0, 0, .09) 0px -6px 4px);
        width: 100%;
        transform: rotate(180deg);
        height: $zigzag-size;
        @include md {
          top: -2px; } }
    &:after {
        background: linear-gradient(-45deg, #ffffff $zigzag-size/2, transparent 0), linear-gradient(45deg, #ffffff $zigzag-size/2, transparent 0);
        background-position: left-bottom;
        background-repeat: repeat-x;
        background-size: $zigzag-size $zigzag-size;
        content: " ";
        display: block;
        position: absolute;
        bottom: 0px;
        left: 0px;
        -webkit-filter: drop-shadow(rgba(0, 0, 0, .09) 0px -6px 4px);
        width: 100%;
        height: $zigzag-size;
        @include md {
          bottom: -2px; } } }
