$primary-bg-color: #AE3E5B;
$secondary-bg-color: #f33155;

$color-shadow: #453C56;

$dark: #343434;

$max-width: 768px;
$white: #fff;

$gradient-primary: #AE3E5B;
$gradient-secondary: #453C56;

$transition: 250ms ease-in-out;
